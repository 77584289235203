<template>
	<Meeting></Meeting>
</template>

<script>
import Meeting from "@/components/shared/tools/meeting/Index.vue";

export default {
	name: "MeetPage",

	components: {
		Meeting,
	},
};
</script>
